import _definePage_default_0 from '/opt/application/web/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/application/web/src/pages/dashboard/index.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/opt/application/web/src/pages/dashboard/documentation/index.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/opt/application/web/src/pages/dashboard/geolocation/view/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/opt/application/web/src/pages/dashboard/integration/index.vue?definePage&vue&lang.tsx'
import _definePage_sidepanel_6 from '/opt/application/web/src/pages/dashboard/integration/index@sidepanel.vue?definePage&vue&lang.tsx'
import _definePage_topbar_7 from '/opt/application/web/src/pages/dashboard/integration/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/opt/application/web/src/pages/dashboard/manual-posting/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_9 from '/opt/application/web/src/pages/dashboard/manual-posting/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/opt/application/web/src/pages/dashboard/my-organization/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_11 from '/opt/application/web/src/pages/dashboard/my-organization/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/opt/application/web/src/pages/dashboard/order/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_13 from '/opt/application/web/src/pages/dashboard/order/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/opt/application/web/src/pages/dashboard/order/view/[id].vue?definePage&vue&lang.tsx'
import _definePage_topbar_15 from '/opt/application/web/src/pages/dashboard/order/view/[id]@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/opt/application/web/src/pages/dashboard/product/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_17 from '/opt/application/web/src/pages/dashboard/product/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/opt/application/web/src/pages/dashboard/shipment/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_19 from '/opt/application/web/src/pages/dashboard/shipment/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/opt/application/web/src/pages/dashboard/shippingProfile.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/opt/application/web/src/pages/dashboard/shippingTable/index.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from '/opt/application/web/src/pages/dashboard/statement.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from '/opt/application/web/src/pages/dashboard/stats.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from '/opt/application/web/src/pages/dashboard/support.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from '/opt/application/web/src/pages/track-by-id/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_26 from '/opt/application/web/src/pages/tracking.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
    {
      path: '/',
      name: '/',
      component: () => import('/opt/application/web/src/pages/index.vue'),
      /* no children */
    },
    _definePage_default_0
  ),
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/opt/application/web/src/pages/dashboard.vue'),
    children: [
      _mergeRouteRecord(
        {
          path: '',
          name: '/dashboard/',
          component: () => import('/opt/application/web/src/pages/dashboard/index.vue'),
          /* no children */
        },
        _definePage_default_2
      ),
      {
        path: 'documentation',
        /* internal name: '/dashboard/documentation' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/documentation/',
              component: () => import('/opt/application/web/src/pages/dashboard/documentation/index.vue'),
              /* no children */
            },
            _definePage_default_3
          ),
          {
            path: '_collapsible',
            name: '/dashboard/documentation/_collapsible',
            component: () => import('/opt/application/web/src/pages/dashboard/documentation/_collapsible.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'geolocation',
        /* internal name: '/dashboard/geolocation' */
        /* no component */
        children: [
          {
            path: 'view',
            /* internal name: '/dashboard/geolocation/view' */
            /* no component */
            children: [
              _mergeRouteRecord(
                {
                  path: ':id',
                  name: '/dashboard/geolocation/view/[id]',
                  component: () => import('/opt/application/web/src/pages/dashboard/geolocation/view/[id].vue'),
                  /* no children */
                },
                _definePage_default_4
              )
            ],
          }
        ],
      },
      {
        path: 'integration',
        /* internal name: '/dashboard/integration' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/integration/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/integration/index.vue'),
                'sidepanel': () => import('/opt/application/web/src/pages/dashboard/integration/index@sidepanel.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/integration/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_5,
            _definePage_sidepanel_6,
            _definePage_topbar_7
          )
        ],
      },
      {
        path: 'manual-posting',
        /* internal name: '/dashboard/manual-posting' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/manual-posting/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/manual-posting/index.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/manual-posting/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_8,
            _definePage_topbar_9
          )
        ],
      },
      {
        path: 'my-organization',
        /* internal name: '/dashboard/my-organization' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/my-organization/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/my-organization/index.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/my-organization/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_10,
            _definePage_topbar_11
          ),
          {
            path: '_general',
            name: '/dashboard/my-organization/_general',
            component: () => import('/opt/application/web/src/pages/dashboard/my-organization/_general.vue'),
            /* no children */
          },
          {
            path: '_invitations',
            name: '/dashboard/my-organization/_invitations',
            component: () => import('/opt/application/web/src/pages/dashboard/my-organization/_invitations.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'order',
        /* internal name: '/dashboard/order' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/order/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/order/index.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/order/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_12,
            _definePage_topbar_13
          ),
          {
            path: 'custom-headers',
            name: '/dashboard/order/custom-headers',
            component: () => import('/opt/application/web/src/pages/dashboard/order/custom-headers.vue'),
            /* no children */
          },
          {
            path: 'view',
            /* internal name: '/dashboard/order/view' */
            /* no component */
            children: [
              {
                path: '_commission-info',
                name: '/dashboard/order/view/_commission-info',
                component: () => import('/opt/application/web/src/pages/dashboard/order/view/_commission-info.vue'),
                /* no children */
              },
              {
                path: '_fragment-info',
                name: '/dashboard/order/view/_fragment-info',
                component: () => import('/opt/application/web/src/pages/dashboard/order/view/_fragment-info.vue'),
                /* no children */
              },
              {
                path: '_top-notice',
                name: '/dashboard/order/view/_top-notice',
                component: () => import('/opt/application/web/src/pages/dashboard/order/view/_top-notice.vue'),
                /* no children */
              },
              _mergeRouteRecord(
                {
                  path: ':id',
                  name: '/dashboard/order/view/[id]',
                  components: {
                    'default': () => import('/opt/application/web/src/pages/dashboard/order/view/[id].vue'),
                    'topbar': () => import('/opt/application/web/src/pages/dashboard/order/view/[id]@topbar.vue')
                  },
                  /* no children */
                },
                _definePage_default_14,
                _definePage_topbar_15
              )
            ],
          }
        ],
      },
      {
        path: 'product',
        /* internal name: '/dashboard/product' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/product/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/product/index.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/product/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_16,
            _definePage_topbar_17
          ),
          {
            path: 'plan-section',
            name: '/dashboard/product/plan-section',
            component: () => import('/opt/application/web/src/pages/dashboard/product/plan-section.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'shipment',
        /* internal name: '/dashboard/shipment' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/shipment/',
              components: {
                'default': () => import('/opt/application/web/src/pages/dashboard/shipment/index.vue'),
                'topbar': () => import('/opt/application/web/src/pages/dashboard/shipment/index@topbar.vue')
              },
              /* no children */
            },
            _definePage_default_18,
            _definePage_topbar_19
          )
        ],
      },
      _mergeRouteRecord(
        {
          path: 'shippingProfile',
          name: '/dashboard/shippingProfile',
          component: () => import('/opt/application/web/src/pages/dashboard/shippingProfile.vue'),
          /* no children */
        },
        _definePage_default_20
      ),
      {
        path: 'shippingTable',
        /* internal name: '/dashboard/shippingTable' */
        /* no component */
        children: [
          _mergeRouteRecord(
            {
              path: '',
              name: '/dashboard/shippingTable/',
              component: () => import('/opt/application/web/src/pages/dashboard/shippingTable/index.vue'),
              /* no children */
            },
            _definePage_default_21
          )
        ],
      },
      _mergeRouteRecord(
        {
          path: 'statement',
          name: '/dashboard/statement',
          component: () => import('/opt/application/web/src/pages/dashboard/statement.vue'),
          /* no children */
        },
        _definePage_default_22
      ),
      _mergeRouteRecord(
        {
          path: 'stats',
          name: '/dashboard/stats',
          component: () => import('/opt/application/web/src/pages/dashboard/stats.vue'),
          /* no children */
        },
        _definePage_default_23
      ),
      _mergeRouteRecord(
        {
          path: 'support',
          name: '/dashboard/support',
          component: () => import('/opt/application/web/src/pages/dashboard/support.vue'),
          /* no children */
        },
        _definePage_default_24
      )
    ],
  },
  {
    path: '/track-by-id',
    /* internal name: '/track-by-id' */
    /* no component */
    children: [
      {
        path: '_order-box',
        name: '/track-by-id/_order-box',
        component: () => import('/opt/application/web/src/pages/track-by-id/_order-box.vue'),
        /* no children */
      },
      _mergeRouteRecord(
        {
          path: ':id',
          name: '/track-by-id/[id]',
          component: () => import('/opt/application/web/src/pages/track-by-id/[id].vue'),
          /* no children */
        },
        _definePage_default_25
      )
    ],
  },
  _mergeRouteRecord(
    {
      path: '/tracking',
      name: '/tracking',
      component: () => import('/opt/application/web/src/pages/tracking.vue'),
      /* no children */
    },
    _definePage_default_26
  )
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

